<template>
  <ml-grid>
    <div class="tw-col-span-5" v-if="item.id">
      <v-form ref="form" v-model="valid" :lazy-validation="lazy" @submit.prevent="onSubmit()">
        <div>
          <v-switch
              v-model="item.auto_booking"
              label="Auto booking"
              :persistent-hint=true
              color="#2d2d2d"
              :ripple="false"
              hint="Enable Auto-Booking. If you have Auto-Booking on, it will apply to all yours offers. Couples who meet your requirements will be able to automatically book your services."
          />
        </div>

        <div>
          <!--
          <v-switch
              v-model="item.area_coverage_check"
              label="Check area coverage"
              :persistent-hint=true
              color="#2d2d2d"
              :ripple="false"
              hint="Check area coverage for booking"
          />
          -->
          <div v-if="item.area_coverage_check" class="tw-mt-6">
            <!--
            <v-autocomplete
                v-model="item.countries"
                :disabled="!item.area_coverage_check"
                :items="countries"
                item-value="id"
                item-text="name"
                :rules="[rules.required_select]"
                label="States area coverage selection"
                multiple
                chips
                :clearable="true"
                :hide-selected="true"
                :deletable-chips="true"
            >
            </v-autocomplete>
            -->
            
            <ml-label for="products">States area coverage selection</ml-label>
            <div class="tw-mt-2">
              <v-select
                  v-model="item.countries"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  multiple
                  chips
                  dense
              >

                <template v-slot:selection="{ item }">
                  <v-chip color="white">
                        <span>{{ item.name }}</span>
                      </v-chip>
                </template>

                <template v-slot:item="{item, attrs, on}">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <div class="col">
                          {{ item.name }}
                          </div>
                          <div class="col" align="right">
                              <span class="tw-text-red">{{item.code}}</span>
                          </div>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
  
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @mousedown.prevent
                    @click="toggleCountries"
                  >
                    <v-list-item-action>
                      <v-icon :color="item.countries.length > 0 ? 'grey darken-4' : ''">
                        {{ iconProduct }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select All
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>


                <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                    <v-list-item disabled>
                      <v-list-item-avatar color="grey lighten-3">
                        <v-icon>
                          mdi-basket-fill
                        </v-icon>
                      </v-list-item-avatar>

                  <v-list-item-content v-if="likesAllCountries">
                    <v-list-item-title>
                      All states are selected
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-content v-else-if="likesSomeCountries">
                    <v-list-item-title>
                      States Count
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.countries.length }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content v-else>
                    <v-list-item-title>
                      Please, select one of states?
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Go ahead, make a selection above!
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                </template>      

              </v-select>
              <ml-error-msg :msg="errors['countries']" />
            </div>
                

        

          </div>
        </div>
        <div class="tw-mt-6">
          <button type="submit"
                  class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
            Save & exit
          </button>
        </div>
      </v-form>


    </div>
  </ml-grid>
</template>

<script>

import MlGrid from "@/components/layout/MlGrid";
import MlLabel from "@/components/layout/form/MlLabel";
import i18n from '@/i18n'
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";

export default {
  components: {
    MlGrid,MlLabel,MlErrorMsg
  },
  middleware: ['auth', 'vendor'],
  metaInfo() {
    return {
      title: i18n.t('settings'),
      meta:[
        {
          name: 'description',
          content: i18n.t('description.vendors/settings'),
        },
      ]
    }
  },
  data() {
    return {
      valid: true,
      lazy: true,
      loading: false,
      item: {
        vendor_availability_check: null,
        products_availability_check: null,
        set_my_available_dates: null,
        auto_booking: null,
        countries: [],
        cities: [],
        area_coverage_check: true
      },
      countries: [],
      cities: [],
      searchCities: null,
      loadingCities: false,
      errors: [],
      errored: false,
      rules: {
        required_select: v => this.area_coverage_check === true ? false : !!(v && v.length) || 'Item is required',
      },
    }
  },
  computed:{
      likesAllCountries () {
        return this.item.countries.length === this.countries.length
      },
      likesSomeCountries () {
        return this.item.countries.length > 0 && !this.likesAllCountries
      },
      iconProduct () {
        if (this.likesAllCountries) return 'mdi-close-box'
        if (this.likesSomeCountries) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },
  mounted() {
    this.getItem();
    this.fetchCountries();
  },
  methods: {
    validateForm() {

      console.log("validate form...");
      this.valid = true;
      this.errors = [];
      let label = '';


      if (this.item.countries instanceof Array && this.item.countries.length === 0 || !this.item.countries) {
        label = "Area of Coverage required.";
        this.errors.push(label);
        this.errors["countries"] = label;
      }

      if (!this.errors.length) {
        this.valid = true;
        return true;
      }

      this.valid = false;
      return false;

    },
    toggleCountries () {
        this.$nextTick(() => {
          if (this.likesAllCountries) {
            this.item.countries = []
          } else {
            this.item.countries = this.countries.slice()
          }
          this.item.countries = this.item.countries.map(a => a.id);
        });
    },
    onSubmit(){
      if(this.validateForm()) this.updateItem();
    },
    async updateItem() {

      await this.$refs.form.validate();
      if (!this.valid) return;

      let uri = '/api/vendor/settings/' + this.item.id;
      this.$http.patch(uri, this.item)
          .then((response) => {
            this.$toast.success(response.data.message, "Success")
            this.$router.push({name: 'vendors.dashboard'})
          });
    }, 
    getItem() {
      this.loading = true;
      let uri = '/api/vendor/settings';
      this.$http.get(uri)
          .then(({data}) => {
            this.item = data.data;
            this.item.countries = data.data.countries.map(a => a.id);
            this.cities = data.data.cities;
            this.item.cities = data.data.cities.map(a => a.id);
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() =>
          this.loading = false
      )

    },
    fetchCountries() {
      this.loading = true;
      this.$http.get('api/countries')
          .then(({data}) => {
            this.countries = data.data;
          })
          .catch(error => {
            console.log('Error loading categories data: ' + error)
            this.errored = true
          })
          .finally(() => this.loading = false)
    },
    fetchCities(query) {
      console.log(query);
      const params = {
        "filter[name]": query,
        "filter[country_id]": this.item.countries,
        "page": this.$route.query.page
      };

      this.loadingCities = true;
      this.$http.get('api/cities', {params})
          .then(({data}) => {
            this.cities = this.cities.concat(data.data);
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          })
          .finally(() =>
              this.loadingCities = false
          )

    },
    clearAll() {
      this.cities = []
    }
  },
  watch: {
    searchCities(val) {

      if (this.searchCities === null) return
      if (this.searchCities.length < 3) return

      // Items have already been loaded
      //if (this.formData.step4.cities.length > 0) return

      // Items have already been requested
      if (this.loadingCities) return

      this.loadingCities = true


      // Debounce the input and wait for a pause of at
      // least 200 milliseconds. This can be changed to
      // suit your needs.
      //debounce(this.fetchCities, 200)(val, this)
      setTimeout(function () {
        this.fetchCities(val)
      }.bind(this), 200);

    },
  }
}
</script>







